var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.supplier ? _c('div', [_c('SaveBar'), _c('v-card', {
    staticClass: "px-5 ml-5 mt-10 mb-10 pb-10",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "space-around"
    }
  }, [_c('v-col', {
    staticClass: "pa-0 col- 12 col-md-6"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('EditSupplierBillingAddress')))]), _c('v-card-text', {
    staticClass: "mb-10 pb-10"
  }, [_c('formAddress', {
    ref: "mySupplier",
    attrs: {
      "address": _vm.supplier.address,
      "disabled": _vm.currentModified !== 'address' && _vm.currentModified !== null || !_vm.currentUserIsAdmin,
      "restricted-country-list": [],
      "is-valid-v-a-t": _vm.isValidVAT,
      "required-company": true,
      "required-zip-code": true,
      "required-phone-number": true,
      "required-v-a-tnumber": true
    },
    on: {
      "validateVAT": _vm.validateVAT,
      "modified": _vm.modifyAddress,
      "updated": _vm.updateAddress
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-0 col-12 col-md-6 col-lg-5"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('GeneralInformation')))]), _c('v-card-text', [_c('v-form', {
    ref: "supplierForm"
  }, [_vm.supplier ? _c('v-text-field', {
    ref: "supplierNameForm",
    attrs: {
      "label": _vm.$t('nameOrganization'),
      "rules": [function (value) {
        return !!value.trim() || _this.$t('nameRules');
      }],
      "outlined": "",
      "required": "",
      "disabled": _vm.currentModified !== 'name' && _vm.currentModified !== null || !_vm.currentUserIsAdmin
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.modifySupplierName.apply(null, arguments);
      },
      "input": function input($event) {
        return _vm.modifiedValue('name', _vm.$refs.supplierNameForm);
      }
    },
    model: {
      value: _vm.supplier.name,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier, "name", $$v);
      },
      expression: "supplier.name"
    }
  }) : _vm._e()], 1)], 1), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('quoteConfiguration')) + " ")]), _c('v-card-text', [_c('h3', [_vm._v(_vm._s(_vm.$t('defaultNote')))]), _c('v-textarea', {
    ref: "quoteNote",
    attrs: {
      "disabled": _vm.currentModified !== 'quoteConfiguration' && _vm.currentModified !== null || !_vm.currentUserIsAdmin,
      "rows": "3",
      "auto-grow": "",
      "outlined": ""
    },
    on: {
      "input": function input($event) {
        return _vm.modifiedValue('quoteConfiguration', _vm.$refs.quoteNote);
      }
    },
    model: {
      value: _vm.supplier.quoteConfiguration.note,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier.quoteConfiguration, "note", $$v);
      },
      expression: "supplier.quoteConfiguration.note"
    }
  }), _c('h3', [_vm._v(_vm._s(_vm.$t('defaultDeliveryNote')))]), _c('v-textarea', {
    ref: "quoteDeliveryNote",
    attrs: {
      "disabled": _vm.currentModified !== 'quoteConfiguration' && _vm.currentModified !== null || !_vm.currentUserIsAdmin,
      "rows": "3",
      "auto-grow": "",
      "outlined": ""
    },
    on: {
      "input": function input($event) {
        return _vm.modifiedValue('quoteConfiguration', _vm.$refs.quoteDeliveryNote);
      }
    },
    model: {
      value: _vm.supplier.quoteConfiguration.deliveryNote,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier.quoteConfiguration, "deliveryNote", $$v);
      },
      expression: "supplier.quoteConfiguration.deliveryNote"
    }
  }), _c('v-tooltip', {
    attrs: {
      "left": "",
      "content-class": "tooltip-opaque"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('h3', [_vm._v(_vm._s(_vm.$t('defaultFooterDocument')) + " "), _c('v-icon', {
          staticClass: "pb-1",
          attrs: {
            "small": "",
            "color": "info"
          }
        }, [_vm._v(" fas fa-question-circle ")])], 1)])];
      }
    }], null, false, 379828001)
  }, [_c('div', [_c('v-img', {
    attrs: {
      "max-width": "800px",
      "src": require("../../../assets/quoteFooter.png"),
      "contain": ""
    }
  })], 1)]), _c('v-textarea', {
    ref: "footerConfigurationRef",
    attrs: {
      "disabled": _vm.currentModified !== 'quoteConfiguration' && _vm.currentModified !== null || !_vm.currentUserIsAdmin,
      "rules": _vm.rulesFooter,
      "hint": _vm.$t('WillBeAtEachPageFooter'),
      "persistent-hint": "",
      "rows": "3",
      "auto-grow": "",
      "outlined": ""
    },
    on: {
      "keydown": _vm.verifyCharacter,
      "input": function input($event) {
        return _vm.modifiedValue('quoteConfiguration', _vm.$refs.footerConfigurationRef);
      }
    },
    model: {
      value: _vm.supplier.quoteConfiguration.footer,
      callback: function callback($$v) {
        _vm.$set(_vm.supplier.quoteConfiguration, "footer", $$v);
      },
      expression: "supplier.quoteConfiguration.footer"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }