<template>
  <div v-if="supplier">
    <SaveBar></SaveBar>
    <v-card
      flat
      class="px-5 ml-5 mt-10 mb-10 pb-10"
    >
      <v-row justify="space-around">
        <v-col class="pa-0 col- 12 col-md-6">
          <v-card-title>{{ $t('EditSupplierBillingAddress') }}</v-card-title>
          <v-card-text class="mb-10 pb-10">
            <formAddress
              ref="mySupplier"
              :address="supplier.address"
              :disabled="currentModified !== 'address' && currentModified !== null || !currentUserIsAdmin"
              :restricted-country-list="[]"
              :is-valid-v-a-t="isValidVAT"
              :required-company="true"
              :required-zip-code="true"
              :required-phone-number="true"
              :required-v-a-tnumber="true"
              @validateVAT="validateVAT"
              @modified="modifyAddress"
              @updated="updateAddress"
            >
            </formAddress>
          </v-card-text>
        </v-col>
        <v-col class="pa-0 col-12 col-md-6 col-lg-5">
          <v-card-title>{{ $t('GeneralInformation') }}</v-card-title>
          <v-card-text>
            <v-form ref="supplierForm">
              <v-text-field
                v-if="supplier"
                ref="supplierNameForm"
                v-model="supplier.name"
                :label="$t('nameOrganization')"
                :rules="[(value) => !!value.trim() || this.$t('nameRules')]"
                outlined
                required
                :disabled="currentModified !== 'name' && currentModified !== null || !currentUserIsAdmin"
                @keyup.enter="modifySupplierName"
                @input="modifiedValue('name', $refs.supplierNameForm)"
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-title>
            {{ $t('quoteConfiguration') }}
          </v-card-title>
          <v-card-text>
            <h3>{{ $t('defaultNote') }}</h3>
            <v-textarea
              ref="quoteNote"
              :disabled="currentModified !== 'quoteConfiguration' && currentModified !== null || !currentUserIsAdmin"
              rows="3"
              auto-grow
              v-model="supplier.quoteConfiguration.note"
              outlined
              @input="modifiedValue('quoteConfiguration', $refs.quoteNote)"
            >
            </v-textarea>
            <h3>{{ $t('defaultDeliveryNote') }}</h3>
            <v-textarea
              ref="quoteDeliveryNote"
              :disabled="currentModified !== 'quoteConfiguration' && currentModified !== null || !currentUserIsAdmin"
              rows="3"
              auto-grow
              v-model="supplier.quoteConfiguration.deliveryNote"
              outlined
              @input="modifiedValue('quoteConfiguration', $refs.quoteDeliveryNote)"
            >
            </v-textarea>
            
            <v-tooltip left content-class="tooltip-opaque">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <h3>{{ $t('defaultFooterDocument') }}
                    <v-icon
                      small
                      color="info"
                      class="pb-1"
                    >
                      fas fa-question-circle
                    </v-icon>
                  </h3>

                </span>
              </template>
              <div>
                <v-img
                  max-width="800px"
                  src="../../../assets/quoteFooter.png"
                  contain
                >
                </v-img>
              </div>
            </v-tooltip>
            <v-textarea
              ref="footerConfigurationRef"
              :disabled="currentModified !== 'quoteConfiguration' && currentModified !== null || !currentUserIsAdmin"
              :rules="rulesFooter"
              :hint="$t('WillBeAtEachPageFooter')"
              persistent-hint
              rows="3"
              auto-grow
              v-model="supplier.quoteConfiguration.footer"
              outlined
              @keydown="verifyCharacter"
              @input="modifiedValue('quoteConfiguration', $refs.footerConfigurationRef)"
            >
            </v-textarea>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { EventBus, FormAddress, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
import SaveBar from '../../../components/saveBar/SaveBar';

const i18nData = require('./pageAdministrationGeneral.i18n.json');

export default {
  name: 'PageAdministrationGeneral',
  components: { SaveBar, FormAddress },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      supplier: null,
      currentUserIsAdmin: false,
      currentModified: null,
      isValidVAT: false,
      rulesFooter: [
        (v) => { return v.length <= 200 || this.$t('NCharactersMax', {n: 200}); },
      ],
      rulesPaymentConfiguration: [
        (v) => { return v.length <= 1000 || this.$t('NCharactersMax', {n: 1000}); },
      ]
    };
  },
  created() {
    /**
     * GET SUPPLIER
     */
    this.getSupplier();

    /**
     * EVENT TO THE SAVE BAR
     */
    EventBus.$on('saveBarSubmit', this.submitSaveBar);
    EventBus.$on('saveBarCancel', this.cancelSaveBar);
  },
  beforeDestroy() {
    EventBus.$off('saveBarSubmit', this.submitSaveBar);
    EventBus.$off('saveBarCancel', this.cancelSaveBar);
  },
  methods: {
    modifiedValue(key, ref) {
      if (this.currentModified !== key) {
        this.currentModified = key;
        EventBus.$emit('displaySaveBar', key);
        if (ref) {
          setTimeout(
            function() {
              ref.focus();
            },
            10
          );
        }
      }
    },
    modifyAddress(modified, ref) {
      if (modified) this.modifiedValue('address', ref);
    },
    updateAddress(newAddress) {
      this.supplier.address = newAddress;
    },
    submitSaveBar(key) {
      switch(key) {
      case 'name':
        this.modifySupplierName();
        break;
      case 'address':
        this.modifySupplierAddress();
        break;
      case 'quoteConfiguration':
        this.modifySupplierQuoteConfiguration();
        break;
      case 'paymentConfiguration':
        this.modifySupplierPaymentConfiguration();
        break;
      }
    },
    cancelSaveBar(key) {
      this.getSupplier();
    },

    /**
     * GET SUPPLIER
     */
    getSupplier() {
      this.$apiInstance.getSupplier(this.$route.params.supplierUUID).then(
        (supplierData) => {
          this.supplier = supplierData;
          this.getSupplierRoles();
          this.currentModified = null;
          EventBus.$emit('hideSaveBar');
        },
        (error) => {
          /**
           * ERROR GET PRINTER
           */
          ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * GET SUPPLIER ROLES
     */
    getSupplierRoles() {
      this.$apiInstance.getSupplierRoles(this.$route.params.supplierUUID).then(
        (supplierRolesData) => {
          this.supplierRolesData = supplierRolesData;
          this.roleModified = false;
          this.supplierRolesData.admin.forEach((user) => {
            if (user._id === this.$appConfig.user._id) {
              this.currentUserIsAdmin = true;
            }
          });
        },

        (error) => {
          /**
           * ERROR GET SUPPLIER ROLES
           */
          this.supplierRolesErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * MODIFY SUPPLIER NAME
     */
    modifySupplierName() {
      if(this.$refs.supplierForm.validate()) {
        const modifySupplierNameBody = new this.$BcmModel.ModifySupplierNameBody(
          this.supplier.name
        );
        this.$apiInstance
          .modifySupplierName(
            this.$route.params.supplierUUID,
            modifySupplierNameBody
          )
          .then(
            () => {
              this.$notification.notify('SUCCESS',this.$t('SupplierNameSuccessfullyModified'));
              EventBus.$emit('reloadOrganizationSelector');
              this.getSupplier();
            },
            (error) => {
              /**
               * ERROR MODIFY PRINTER
               */
              this.currentModified = null;
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      }else{
        this.currentModified = null;
      }
    },
    /**
     * Validate VAT
     */
    async validateVAT(countryCode, vat) {
      this.isValidVAT = await this.$apiInstance.validateEuropeanVAT(countryCode, vat)
        .then((data) => {
          return true;
        }, (error) => {
          return false;
        });
    },
    /**
     * MODIFY SUPPLIER ADDRESS
     */
    modifySupplierAddress() {
      if(this.$refs.mySupplier.validate()) {
        const modifySupplierAddressBody = new this.$BcmModel.ModifyAddressBody(
          this.supplier.address.country,
          this.supplier.address.firstname,
          this.supplier.address.lastname,
          this.supplier.address.company,
          this.supplier.address.streetAddress,
          this.supplier.address.zipCode,
          this.supplier.address.city,
          this.supplier.address.state,
          this.supplier.address.phoneNumber,
          this.supplier.address.VATnumber
        );
        this.$apiInstance
          .modifySupplierAddress(
            this.$route.params.supplierUUID,
            modifySupplierAddressBody
          )
          .then(
            () => {
              this.$notification.notify('SUCCESS',this.$t('SupplierAddressSuccessfullyModified'));
              this.getSupplier();
            },
            (error) => {
              /**
               * ERROR MODIFY PRINTER
               */
              this.currentModified = null;
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      }else{
        this.currentModified = null;
      }
    },
    modifySupplierQuoteConfiguration() {
      const modifySupplierQuoteConfigurationBody = new this.$BcmModel.ModifySupplierQuoteConfigurationBody(
        this.supplier.quoteConfiguration.footer,
        this.supplier.quoteConfiguration.note,
        this.supplier.quoteConfiguration.deliveryNote
      );
      this.$apiInstance.modifySupplierQuoteConfiguration(
        this.$route.params.supplierUUID,
        modifySupplierQuoteConfigurationBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('QuoteConfigurationModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplier();
      });
    },
    modifySupplierPaymentConfiguration() {
      const modifySupplierPaymentConfigurationBody = new this.$BcmModel.ModifySupplierPaymentConfigurationBody(
        this.supplier.paymentConfiguration
      );
      this.$apiInstance.modifySupplierPaymentConfiguration(
        this.$route.params.supplierUUID,
        modifySupplierPaymentConfigurationBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('PaymentConfigurationModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplier();
      });
    },
    verifyCharacter(event) {
      if(event.key === 'Enter') {
        event.preventDefault();
      }
    }
  },
};
</script>

<style scoped>
  .tooltip-opaque {
    opacity: 1 !important;
  }
</style>