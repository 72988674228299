<template lang="html" src="./saveBar.template.vue"></template>

<style lang="scss" src="./saveBar.scss"></style>

<script>
import { EventBus } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./saveBar.i18n');

export default {
  name: 'SaveBar',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      key: null,
      show: false,
    };
  },
  created() {
    EventBus.$on('displaySaveBar', this.displayBar);
    EventBus.$on('hideSaveBar', this.hideSaveBar);
  },
  destroyed() {
    EventBus.$off('displaySaveBar', this.displayBar);
    EventBus.$off('hideSaveBar', this.hideSaveBar);
  },
  methods: {
    displayBar(key) {
      if (key !== this.key) {
        this.key = key;
      }
      if (!this.show) {
        this.show = true;
      }
    },
    saveBarSubmit() {
      EventBus.$emit('saveBarSubmit', this.key);
      this.show = false;
    },
    saveBarCancel() {
      EventBus.$emit('saveBarCancel', this.key);
      this.hideSaveBar();
    },
    hideSaveBar() {
      this.show = false;
      this.key = null;
    },
  },
};
</script>
